"use client"
import styled from 'styled-components';
// import { device } from '../utils/deviceSizes';

export const NotFoundPageStyles = styled.div`

  .wrapper {
    background-color: var(--primary);
    color: #fff;
    padding: 5rem;
    margin: 5rem;
    border-radius: 20px;
  }


  button {
    background-color: var(--secondary);
    margin-top: 4rem;
    text-transform: uppercase;
  }
`